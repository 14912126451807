import React, { useState } from "react";
import useMessage from "../hooks/use-message.component";
import SubmissionDataService from "../../services/submission.service";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { LangContext } from "../../contexts/lang-context";
import { content } from "../content/submission-content.component";

export default function SubmissionForm({ setSubmitted }) {
  const { message, setError } = useMessage();
  const [lang] = React.useContext(LangContext);

  const [submissionInfoReq] = useState({
    firstname: true,
    lastname: true,
    prefix: false,
    initials: false,
    gender: true,
    nationality: true,
    birthdate: true,
    birthplace: false,
    birthcountry: true,
    residentiallocation: true,
    zipcode: true,
    street: true,
    housenumber: true,
    phonenumber: true,
    whatsapp: false,
    email: true
  });

  const [submissionInfo, setSubmissionInfo] = useState({
    firstname: "",
    lastname: "",
    prefix: "",
    initials: "",
    gender: "",
    nationality: "",
    birthdate: "",
    birthplace: "",
    birthcountry: "",
    residentiallocation: "",
    zipcode: "",
    street: "",
    housenumber: "",
    phonenumber: "",
    whatsapp: "",
    email: ""
  });

  const [errors, setErrors] = useState([]);
  const [errors2, setErrors2] = useState([]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phonenumber) => {
    return String(phonenumber)
      .toLowerCase()
      .match(
        /^((^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$))$/
      );
  };

  const validateZipcode = (zipcode) => {
    return String(zipcode)
      .toLowerCase()
      .match(
        /^(([1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}))$/
      );
  };

  const saveSubmission = async () => {
    return await SubmissionDataService.create(submissionInfo)
      .then(res => {
        return res.data;
      })
      .catch(e => {
        return false;
      });
  }

  const handleChange = (event) => {
    setSubmissionInfo({ ...submissionInfo, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    let errors = {};
    let errors2 = {};
    let formIsValid = true;

    Object.entries(submissionInfo).forEach(entry => {
      const [key, value] = entry;
      if (submissionInfoReq[key]) {
        if (!value || value.trim().length === 0) {
          formIsValid = false;
          errors[key] = content[lang]["messages"]["error_required"];
        }
      }
    });

    if (!errors["email"]) {
      if (!validateEmail(submissionInfo.email)) {
        formIsValid = false;
        errors2["email"] = content[lang]["messages"]["error_mail"];
      }
    }

    if (!errors["phonenumber"]) {
      if (!validatePhone(submissionInfo.phonenumber)) {
        formIsValid = false;
        errors2["phonenumber"] = content[lang]["messages"]["error_phone"];
      }
    }

    if (!errors["whatsapp"]) {
      if (!validatePhone(submissionInfo.whatsapp)) {
        if (submissionInfo.whatsapp && submissionInfo.whatsapp.trim().length > 0) {
          formIsValid = false;
          errors2["whatsapp"] = content[lang]["messages"]["error_app"];
        }
      }
    }

    if (!errors["zipcode"]) {
      if (!validateZipcode(submissionInfo.zipcode)) {
        formIsValid = false;
        errors2["zipcode"] = content[lang]["messages"]["error_zip"];
      }
    }

    if (!formIsValid)
      setError(content[lang]["messages"]["error_incomplete"]);

    setErrors(errors);
    setErrors2(errors2);
    return formIsValid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      if (!(await saveSubmission()))
        setError(content[lang]["messages"]["error_alg"]);
      else {
        confirmAlert({
          title: content[lang]["messages"]["popup"]["title"],
          message: content[lang]["messages"]["popup"]["message"],
          buttons: [
            {
              label: content[lang]["messages"]["popup"]["btnok"]
            }
          ]
        });
        setSubmissionInfo({
          firstname: "",
          lastname: "",
          prefix: "",
          initials: "",
          gender: "",
          nationality: "",
          birthdate: "",
          birthplace: "",
          birthcountry: "",
          residentiallocation: "",
          zipcode: "",
          street: "",
          housenumber: "",
          phonenumber: "",
          whatsapp: "",
          email: ""
        });
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="firstname">{content[lang]["formElements"]["firstname"]} *</label>
              <input
                type="text"
                className={(errors["firstname"] ? 'error' : '') + " form-control"}
                id="firstname"
                value={submissionInfo.firstname}
                onChange={handleChange}
                name="firstname"
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastname">{content[lang]["formElements"]["lastname"]} *</label>
              <input
                type="text"
                className={(errors["lastname"] ? 'error' : '') + " form-control"}
                id="lastname"
                value={submissionInfo.lastname}
                onChange={handleChange}
                name="lastname"
              />
            </div>

            <div className="form-group">
              <label htmlFor="prefix">{content[lang]["formElements"]["prefix"]}</label>
              <input
                type="text"
                className={(errors["prefix"] ? 'error' : '') + " form-control"}
                id="prefix"
                value={submissionInfo.prefix}
                onChange={handleChange}
                name="prefix"
              />
            </div>

            <div className="form-group">
              <label htmlFor="initials">{content[lang]["formElements"]["initials"]}</label>
              <input
                type="text"
                className={(errors["initials"] ? 'error' : '') + " form-control"}
                id="initials"
                value={submissionInfo.initials}
                onChange={handleChange}
                name="initials"
              />
            </div>

            <div className="form-group">
              <label htmlFor="gender">{content[lang]["formElements"]["gender"]["value"]} *</label>

              <select type="text"
                className={(errors["gender"] ? 'error' : '') + " form-control"}
                id="gender"
                value={submissionInfo.gender}
                onChange={handleChange}
                name="gender"
              >
                <option value="">{content[lang]["formElements"]["gender"]["placeholder"]}</option>
                <option value="m">{content[lang]["formElements"]["gender"]["men"]}</option>
                <option value="v">{content[lang]["formElements"]["gender"]["women"]}</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="nationality">{content[lang]["formElements"]["nationality"]} *</label>
              <input
                type="text"
                className={(errors["nationality"] ? 'error' : '') + " form-control"}
                id="nationality"
                value={submissionInfo.nationality}
                onChange={handleChange}
                name="nationality"
              />
            </div>

            <div className="form-group">
              <label htmlFor="birthdate">{content[lang]["formElements"]["birthdate"]} *</label>
              <input
                type="date"
                className={(errors["birthdate"] ? 'error' : '') + " form-control"}
                id="birthdate"
                value={submissionInfo.birthdate}
                onChange={handleChange}
                name="birthdate"
              />
            </div>

            <div className="form-group">
              <label htmlFor="birthplace">{content[lang]["formElements"]["birthplace"]}</label>
              <input
                type="text"
                className={(errors["birthplace"] ? 'error' : '') + " form-control"}
                id="birthplace"
                value={submissionInfo.birthplace}
                onChange={handleChange}
                name="birthplace"
              />
            </div>
          </div>

          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="birthcountry">{content[lang]["formElements"]["birthcountry"]} *</label>
              <input
                type="text"
                className={(errors["birthcountry"] ? 'error' : '') + " form-control"}
                id="birthcountry"
                value={submissionInfo.birthcountry}
                onChange={handleChange}
                name="birthcountry"
              />
            </div>

            <div className="form-group">
              <label htmlFor="residentiallocation">{content[lang]["formElements"]["residentiallocation"]} *</label>
              <input
                type="text"
                className={(errors["residentiallocation"] ? 'error' : '') + " form-control"}
                id="residentiallocation"
                value={submissionInfo.residentiallocation}
                onChange={handleChange}
                name="residentiallocation"
              />
            </div>

            <div className="form-group">
              <label htmlFor="zipcode">{content[lang]["formElements"]["zipcode"]} *</label>
              <input
                type="text"
                className={(errors["zipcode"] || errors2["zipcode"] ? 'error' : '') + " form-control"}
                id="zipcode"
                value={submissionInfo.zipcode}
                onChange={handleChange}
                name="zipcode"
              />
              <span style={{ color: "red" }}>{errors2["zipcode"]}</span>
            </div>

            <div className="form-group">
              <label htmlFor="street">{content[lang]["formElements"]["street"]} *</label>
              <input
                type="text"
                className={(errors["street"] ? 'error' : '') + " form-control"}
                id="street"
                value={submissionInfo.street}
                onChange={handleChange}
                name="street"
              />
            </div>

            <div className="form-group">
              <label htmlFor="housenumber">{content[lang]["formElements"]["housenumber"]} *</label>
              <input
                type="text"
                className={(errors["housenumber"] ? 'error' : '') + " form-control"}
                id="housenumber"
                value={submissionInfo.housenumber}
                onChange={handleChange}
                name="housenumber"
              />
            </div>

            <div className="form-group">
              <label htmlFor="phonenumber">{content[lang]["formElements"]["phonenumber"]} *</label>
              <input
                type="text"
                className={(errors["phonenumber"] || errors2["phonenumber"] ? 'error' : '') + " form-control"}
                id="phonenumber"
                value={submissionInfo.phonenumber}
                onChange={handleChange}
                name="phonenumber"
              />
              <span style={{ color: "red" }}>{errors2["phonenumber"]}</span>
            </div>

            <div className="form-group">
              <label htmlFor="whatsapp">{content[lang]["formElements"]["whatsapp"]}</label>
              <input
                type="text"
                className={(errors["whatsapp"] || errors2["whatsapp"] ? 'error' : '') + " form-control"}
                id="whatsapp"
                value={submissionInfo.whatsapp}
                onChange={handleChange}
                name="whatsapp"
              />
              <span style={{ color: "red" }}>{errors2["whatsapp"]}</span>
            </div>

            <div className="form-group">
              <label htmlFor="email">{content[lang]["formElements"]["email"]} *</label>
              <input
                type="text"
                className={(errors["email"] || errors2["email"] ? 'error' : '') + " form-control"}
                id="email"
                value={submissionInfo.email}
                onChange={handleChange}
                name="email"
              />
              <span style={{ color: "red" }}>{errors2["email"]}</span>
            </div>
          </div>
        </div>
        <button className="btn btn-grid">
          {content[lang]["formElements"]["btn"]}
        </button>
      </form>
      {message["msg"] ? <p className={message["css"] + " mt-2"}>{message["msg"]}</p> : ""}</div>
  );
}
