import React, { useState } from "react";
import LoginService from "../services/login.service";
import PropTypes from 'prop-types';
import useMessage from "./hooks/use-message.component";
 
 export default function Login({ setToken }) {
   const { message, setError } = useMessage();
   const [username, setUserName] = useState("");
   const [password, setPassword] = useState("");
   const [errors, setErrors] = useState([]);
 
   const handleSubmit = e => {
     e.preventDefault();

     if(!handleValidation())
        return;

     var data = {
      username: username,
      password: password
    };

    LoginService.login(data)
      .then(response => {
        if(response.data.token){
          const token = response.data.token;
          setToken(token);
        }else{
          setError("Combinatie van gebruikersnaam/wachtwoord klopt niet");
        }
      })
      .catch(e => {
        setError("Combinatie van gebruikersnaam/wachtwoord klopt niet");
      });
   }

   const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!username || username.trim().length === 0) {
      formIsValid = false;
      errors["username"] = "Dit veld is verplicht";
    }

    if (!password || password.trim().length === 0) {
      formIsValid = false;
      errors["password"] = "Dit veld is verplicht";
    }
    else if(password.replaceAll(/\s/g,'').length < 6){
      formIsValid = false;
      errors["password"] = "Wachtwoord moet minimaal 6 karakters bevatten";
    }

    setErrors(errors);
    return formIsValid;
  }
 
   return(
    <div className="container mt-3">
      <div className="login-wrapper">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Gebruikersnaam</label>
              <input className={(errors["username"] ? 'error' : '') + " form-control"} type="text" name="username" onChange={e => setUserName(e.target.value)} />
              <span style={{ color: "red" }}>{errors["username"]}</span>
            </div>
            <div className="form-group">
              <label htmlFor="password">Wachtwoord</label>
              <input className={(errors["password"] ? 'error' : '') + " form-control"} type="password" name="password" onChange={e => setPassword(e.target.value)} />
              <span style={{ color: "red" }}>{errors["password"]}</span>
            </div>
            <div>
              <button className="btn btn-primary" type="submit">Inloggen</button>
            </div>
          </form>
          {message["msg"] ? <p className={message["css"]}>{message["msg"]}</p> : ""}
        </div>
    </div>
   )
 }
 
 Login.propTypes = {
   setToken: PropTypes.func.isRequired
 };
