const content = {
    NL: {
      paragraph: "Zoek je werk, vul het onderstaande formulier in. Wij zullen je benaderen en kijken of we zo snel mogelijk een passende baan voor je kunnen vinden. Vul de onderstaande velden zo nauwkeurig mogelijk in, zodat we u zo snel mogelijk kunnen bereiken.",
      formElements: {
        firstname: "Voornaam",
        lastname: "Achternaam",
        prefix: "Voorvoegsels",
        initials: "Voorletters",
        gender: {
          value:"Geslacht",
          placeholder:"Kiezen...",
          men:"Man",
          women:"Vrouw"
        },
        nationality: "Nationaliteit",
        birthdate: "Geboortedatum",
        birthplace: "Geboorteplaats",
        birthcountry: "Geboorteland",
        residentiallocation: "Woonplaats",
        zipcode: "Postcode",
        street: "Straat",
        housenumber: "Huisnummer",
        phonenumber: "Telefoonnummer",
        whatsapp: "Whatsapp",
        email: "E-mail",
        btn: "Versturen"
      },
      messages: {
        error_alg:"Er is iets misgegaan, probeer opnieuw!",
        error_required:"Dit veld is verplicht",
        error_mail:"Vul een geldige e-mail in",
        error_phone:"Vul een geldige telefoonnummer in",
        error_app:"Vul een geldige mobiele nummer in",
        error_zip:"Vul een geldige postcode in",
        error_incomplete:"Het formulier is incompleet, controleer uw gegevens",
        popup:{
          title: 'Verzonden',
          message: 'Bedankt voor uw inzending! Wij nemen binnen 3 werkdagen contact met u op.',
          btnok: "OK"
        }
      }
    },
    GB: {
      paragraph: "Find your job, fill in the form below. We will approach you and see if we can find a suitable job for you as soon as possible. Please fill in the fields below as accurately as possible so that we can reach you as quickly as possible.",
      formElements: {
        firstname: "Firstname",
        lastname: "Lastname",
        prefix: "Prefix",
        initials: "Initials",
        gender: {
          value:"Gender",
          placeholder:"Choose...",
          men:"Male",
          women:"Female"
        },
        nationality: "Nationality",
        birthdate: "Birthdate",
        birthplace: "Birthplace",
        birthcountry: "Birthcountry",
        residentiallocation: "Residence",
        zipcode: "Postal code",
        street: "Street",
        housenumber: "House number",
        phonenumber: "Phonenumber",
        whatsapp: "Whatsapp",
        email: "E-mail",
        btn: "Send"
      },
      messages: {
        error_alg:"Something went wrong, try again!",
        error_required:"This field is required",
        error_mail:"Fill in a valid e-mail",
        error_phone:"Fill in a valid phone number",
        error_app:"Fill in a valid mobile number",
        error_zip:"Fill in a valid postal code",
        error_incomplete:"The form is incomplete",
        popup:{
          title: 'Sent',
          message: 'Thanks for your submission! We will contact you within 3 working days.',
          btnok: "OK"
        }
      }
    },
    UA: {
      paragraph: "Знайдіть свою роботу, заповніть форму нижче. Ми зв’яжемося з вами та якомога швидше знайдемо для вас підходящу роботу. Будь ласка, заповніть поля нижче якомога точніше, щоб ми могли зв’язатися з вами якомога швидше.",
      formElements: {
        firstname: "Ім'я",
        lastname: "Прізвище",
        prefix: "Префікси",
        initials: "Ініціали",
        gender: {
          value:"Стать",
          placeholder:"Виберіть...",
          men:"Чоловік",
          women:"Жінка"
        },
        nationality: "Національність",
        birthdate: "Дата народження",
        birthplace: "Місце народження",
        birthcountry: "Країна народження",
        residentiallocation: "місце проживання",
        zipcode: "Поштовий індекс",
        street: "вул",
        housenumber: "Номер будинку",
        phonenumber: "Телефонний номер",
        whatsapp: "WhatsApp",
        email: "Електронна пошта",
        btn: "Надіслати"
      },
      messages: {
        error_alg:"Щось пішло не так, спробуйте ще раз!",
        error_required:"Це поле є обов'язковим",
        error_mail:"Введіть дійсну адресу електронної пошти",
        error_phone:"Введіть дійсний номер телефону",
        error_app:"Введіть дійсний номер мобільного телефону",
        error_zip:"Введіть дійсний поштовий індекс",
        error_incomplete:"Форма неповна, перевірте свої дані",
        popup:{
          title: 'Надісланий',
          message: "Дякуємо за вашу заявку! Ми зв'яжемося з вами протягом 3 робочих днів.",
          btnok: "в порядку"
        }
      }
    }
  }
  
  module.exports = { content }