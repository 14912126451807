import SubmissionForm from "../submission/submission-form.component";
import { content } from "../content/submission-content.component";
import { LangContext } from "../../contexts/lang-context";
import React from "react";
import LanguageSelector from "../language-selector.component";
import FooterComponent from "../elements/footer.component";
import HeaderComponent from "../elements/header.component";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function EmployeePage(props) {
  const [lang] = React.useContext(LangContext);

  return (

    <div className="home-wrapper subpage">
      <div className="mt-3">
        <HeaderComponent/>
        <div style={{backgroundColor:"#e0e0e0"}}>
          <div className="container pb-5 pt-5">
            <h2>Werknemers</h2>
            <div className="pt-3 pb-3">
              <LanguageSelector />
              <p>{content[lang]["paragraph"]}</p>
            </div>

            <SubmissionForm />
          </div>
        </div>
        <FooterComponent/>
      </div>
    </div>
  );
}
