import React, { useState, useEffect } from "react";
import SubmissionDataService from "../../services/submission.service";
import useMessage from "../hooks/use-message.component";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FiRefreshCcw, FiDownload } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { CSVLink } from "react-csv";
import submissionService from "../../services/submission.service";

export default function SubmissionList({token}) {
  const DownloadTypes = {
    All: 0,
    Today: 1,
    ThisWeek: 2
  }
  const [weekNumber, setWeekNumber] = useState(0);
  const { message, setMsg, setError } = useMessage();
  const [rowData, setRowData] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [typeToDownload, setTypeToDownload] = useState(DownloadTypes.Today);
  const [fileName, setFileName] = useState("");
  const [defaultColDef] = useState({
    sortable: true,
    filter: true,
    editable: true
  });
  const [columnDefs] = useState([
    {field: "id", editable: false, headerName: "ID"},
    {field: "firstname", headerName: "Voornaam"},
    {field: "lastname", headerName: "Achternaam"},
    {field: "prefix", headerName: "Voorvoegsels"},
    {field: "initials", headerName: "Voorletters"},
    {field: "gender", headerName: "Geslacht"},
    {field: "nationality", headerName: "Nationaliteit"},
    {field: "birthdate", headerName: "Geboortedatum"},
    {field: "birthplace", headerName: "Geboorteplaats"},
    {field: "birthcountry", headerName: "Geboorteland"},
    {field: "residentiallocation", headerName: "Woonplaats"},
    {field: "zipcode", headerName: "Postcode"},
    {field: "street", headerName: "Straat"},
    {field: "housenumber", headerName: "Huisnummer"},
    {field: "phonenumber", headerName: "phonenumber"},
    {field: "whatsapp", headerName: "whatsapp"},
    {field: "email", headerName: "E-mail"},
    {field: "created", editable: false, headerName: "Aangemaakt"},
    {field: "edited", editable: false, headerName: "Gewijzigd"}
  ]);

  const [headersCsv] = useState([
    {key: "id", label: "ID"},
    {key: "firstname", label: "Voornaam"},
    {key: "lastname", label: "Achternaam"},
    {key: "prefix", label: "Voorvoegsels"},
    {key: "initials", label: "Voorletters"},
    {key: "gender", label: "Geslacht"},
    {key: "nationality", label: "Nationaliteit"},
    {key: "birthdate", label: "Geboortedatum"},
    {key: "birthplace", label: "Geboorteplaats"},
    {key: "birthcountry", label: "Geboorteland"},
    {key: "residentiallocation", label: "Woonplaats"},
    {key: "zipcode", label: "Postcode"},
    {key: "street", label: "Straat"},
    {key: "housenumber", label: "Huisnummer"},
    {key: "phonenumber", label: "phonenumber"},
    {key: "whatsapp", label: "whatsapp"},
    {key: "email", label: "E-mail"},
    {key: "created", label: "Aangemaakt"},
    {key: "edited", label: "Gewijzigd"}
  ]);


  useEffect(() => {
    setWeekNumber(getWeekNumber(new Date()));
    retrieveSubmissions();
  }, []);
  
  const onChangeTypeToDownload = (e) => {
    setTypeToDownload(parseInt(e.target.value));
  }

  const getWeekNumber = (dt) => {
    var d = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
  };
  
  const retrieveSubmissions = () => {
    SubmissionDataService.getAll()
      .then(response => {
        setRowData([]);
        setRowData(response.data.body);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const compareSameWeek = (dateToCompare) => {
    return getWeekNumber(dateToCompare) === weekNumber;
  }

  const refreshList = () =>{
    setSelectedRows([]);
    retrieveSubmissions();
  }

  const onRowEdit = (e) => {
    e.data["token"] = token;
    submissionService.update(e.data).then((res)=>{
      if(res.data)
        setMsg("Gegevens aangepast!");
      else
        setError("Er is iets mis gegaan, probeer opnieuw!");
    })
    .then(()=>{
      refreshList();
    }).catch(e => {
      setError("Er is iets mis gegaan, probeer opnieuw!");
    });
  };

  const onRowDelete = () => {
    var data = {
      idsToDelete: selectedRows,
      token: token
    };
    confirmAlert({
      title: 'Verwijderen',
      message: 'Wilt u de geselecteerde gegevens verwijderen?',
      buttons: [
        {
          label: 'Verwijder',
          onClick: () => {
            submissionService.deleteSelected(data).then((res)=>{
              if(res.data)
                setMsg("Gegevens verwijderd!");
              else
                setError("Er is iets mis gegaan, probeer opnieuw!");
            })
            .then(()=>{
              refreshList();
            }).catch(e => {
              setError("Er is iets mis gegaan, probeer opnieuw!");
            });
          }
        },
        {
          label: 'Annuleer',
          onClick: () => {}
        }
      ]
    });
  }

  const isToday = (date) => {
    const today = new Date();

    if (today.toDateString() === date.toDateString()) {
      return true;
    }

    return false;
  }

  const onRowSelected = (e) => {
    var sr = [...selectedRows];
    if(!sr.includes(e.data))         
      sr.push(e.data);     
    else
      sr.splice(sr.indexOf(e.data), 1);  
    setSelectedRows(sr);
  }

  const downloadData = () => {
    var dtd = [];
    switch(typeToDownload){
      case DownloadTypes.All:
        dtd = rowData;
        break;
      case DownloadTypes.Today:
        dtd =  rowData.filter(row => isToday(new Date(row.created)));
        break;
      case DownloadTypes.ThisWeek:
        dtd = rowData.filter(row => compareSameWeek(new Date(row.created)));
        break;
      default:
        dtd = rowData;
    }
    setFileName(getFileName());
    setDataToDownload(dtd);
  }

  const getFileName = () => {
    switch(typeToDownload){
      case DownloadTypes.All:
        return "inzendingen-all.csv";
      case DownloadTypes.Today:
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        return "inzendingen-"+dd+"-"+mm+"-"+yyyy+".csv";
      case DownloadTypes.ThisWeek:
        return "inzendingen-week-"+weekNumber+".csv";
      default:
        return "inzendingen-all.csv";
    }
  }
    
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="row float-l">
            <button className="btn btn-secondary flex-center padding-10" onClick={refreshList}><FiRefreshCcw/></button>
            
            {selectedRows.length > 0 ? <span className="flex-center">&nbsp;&nbsp;&nbsp;{"Geselecteerd: " +selectedRows.length}&nbsp;&nbsp;</span>: ""}
            {selectedRows.length > 0 ? <span className="flex-center"><button className="btn btn-danger flex-center" onClick={onRowDelete}><AiFillDelete></AiFillDelete></button>&nbsp;&nbsp;</span> : ""}
            {selectedRows.length > 0 ? <span className="flex-center"><CSVLink headers={headersCsv} className="btn btn-success flex-center" filename="inzendingen-selected.csv" data={selectedRows}><FiDownload/></CSVLink></span>: ""}
          </div>
        </div>
        <div className="col-md-6">
          <div className="row float-r">
            <select className="form-control form-control-row" onChange={onChangeTypeToDownload}>             
              <option value={DownloadTypes.Today}>Vandaag</option>
              <option value={DownloadTypes.ThisWeek}>Deze week</option>
              <option value={DownloadTypes.All}>Alles</option>
            </select>
            <CSVLink headers={headersCsv} className="btn btn-success flex-center" filename={fileName} data={dataToDownload} onClick={downloadData}><FiDownload/>&nbsp;Export</CSVLink>
          </div>
        </div>
        <br/><br/>
      </div>
      <div className="ag-theme-alpine" style={{height: 500}}>
        <AgGridReact 
        rowSelection="multiple"
        onRowValueChanged={onRowEdit}
        editType="fullRow" 
        pagination={true} 
        paginationPageSize={20} 
        rowData={rowData} 
        columnDefs={columnDefs} 
        defaultColDef={defaultColDef}
        onRowSelected={onRowSelected}
        ></AgGridReact>
      </div>
      {message["msg"] ? <p className={message["css"] + " mt-2"}>{message["msg"]}</p> : ""}
    </div>
  );
}
