import useMessage from "../hooks/use-message.component";
import React, { useState } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EmailService from "../../services/email.service";

export default function SubmissionFormContact({ setSubmitted }) {
    const { message, setError } = useMessage();
  const [errors, setErrors] = useState([]);
  const [errors2, setErrors2] = useState([]);

  const [contactInfoReq] = useState({
    name: true,
    tel: true,
    email: true,
    comments: false
  });

  const [contactInfo, setContactInfo] = useState({
    name: "",
    tel: "",
    email: "",
    comments: ""
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phonenumber) => {
    return String(phonenumber)
      .toLowerCase()
      .match(
        /^((^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$))$/
      );
  };

  const handleValidation = () => {
    let errors = {};
    let errors2 = {};
    let formIsValid = true;

    Object.entries(contactInfo).forEach(entry => {
      const [key, value] = entry;
      if(contactInfoReq[key]){
        if (!value || value.trim().length === 0) {
          formIsValid = false;
          errors[key] = "Dit veld is verplicht";
        }
      }
    });

    if (!errors["email"]) {
      if(!validateEmail(contactInfo.email)){
        formIsValid = false;
        errors2["email"] = "Vul een geldige e-mail in";
      }
    }

    if (!errors["tel"]) {
      if(!validatePhone(contactInfo.tel)){
        formIsValid = false;
        errors2["tel"] = "Vul een geldige telefoonnummer in";
      }
    }

    if(!formIsValid)
      setError("Het formulier is incompleet, controleer uw gegevens");

    setErrors(errors);
    setErrors2(errors2);
    return formIsValid;
  }

  const handleChange = (event) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };

  const sendEmail = async () => {
    return await EmailService.sendEmail(contactInfo)
      .then(res => {
        return res.data;
      })
      .catch(e => {
        return false;
      });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      // send mail to info@globalmatch.nl
      if(!(await sendEmail())){
        setError("Er is iets misgegaan, probeer opnieuw!");
      }else{
        confirmAlert({
          title: "Verzonden",
          message: "Wij nemen z.s.m. contact met u op.",
          buttons: [
            {
              label: "OK"
            }
          ]
        });
        setContactInfo({ 
          name: "",
          tel: "",
          email: "",
          comments: ""
        });
      }
    }
  };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-5">
                    <div className="form-group">
                        <label htmlFor="name">Naam *</label>
                        <input
                            type="text"
                            className={(errors["name"] ? 'error' : '') + " form-control"}
                            id="name"
                            value={contactInfo.name}
                            onChange={handleChange}
                            name="name"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">E-mail *</label>
                        <input
                            type="text"
                            className={(errors["email"] || errors2["email"] ? 'error' : '') + " form-control"}
                            id="email"
                            value={contactInfo.email}
                            onChange={handleChange}
                            name="email"
                        />
                        <span style={{ color: "red" }}>{errors2["email"]}</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="tel">Telefoon *</label>
                        <input
                            type="text"
                            className={(errors["tel"] || errors2["tel"] ? 'error' : '') + " form-control"}
                            id="tel"
                            value={contactInfo.tel}
                            onChange={handleChange}
                            name="tel"
                        />
                        <span style={{ color: "red" }}>{errors2["tel"]}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="comments">Opmerkingen</label>

                        <textarea 
                        className={(errors["comments"] || errors2["comments"] ? 'error' : '') + " form-control"} 
                        id="comments" 
                        name="comments" 
                        value={contactInfo.comments} 
                        rows="4" 
                        cols="50"
                        maxLength={1250}
                        onChange={handleChange}
                        ></textarea>
                        <span style={{ color: "red" }}>{errors2["comments"]}</span>
                    </div>
                </div>
                <button className="btn btn-grid">
                    Versturen
                </button>
            </form>
            {message["msg"] ? <p className={message["css"] + " mt-2"}>{message["msg"]}</p> : ""}
        </div>
    );
}
