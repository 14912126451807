import React, { useState, useEffect } from "react";
import {Link } from "react-router-dom";
import Login from "../login.component";
import useToken from "../hooks/use-token.component";
import Clock from 'react-live-clock';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { IoMailUnread } from 'react-icons/io5';
import { HiDocumentAdd } from 'react-icons/hi';
import SubmissionList from "./dashboard-submissionlist.component";
import SubmissionForm from "../submission/submission-form.component";
import LanguageSelector from "../language-selector.component";

export default function Dashboard() {
  const Pages = {
    Home: 0,
    Submissions: 1,
    Submissionform: 2
  }

  const { token, setToken, logOut } = useToken();
  const [weekNumber, setWeekNumber] = useState(0);
  const [widthState, setWidthState] = useState(window.matchMedia("(max-width: 992px)").matches);
  const [currentPage, setCurrentPage] = useState(Pages.Home);

  useEffect(() => {
    setWeekNumber(getWeekNumber(new Date()));
    const handler = e => setWidthState(e.matches);
    window.matchMedia("(max-width: 992px)").addEventListener('change', handler);
  }, []);

  const getWeekNumber = (dt) => {
    var d = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
  };

  if(!token)
    return (<Login setToken={setToken}/>);
    
  return (
    <div>
      <span className="close-navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"></span>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
        <Link to={"/admin"} className="navbar-brand" onClick={() => setCurrentPage(Pages.Home)}>
          GlobalMatch
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <span className="nav-link" onClick={() => setCurrentPage(Pages.Home)} data-toggle={widthState === true ? "collapse" : ""} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Dashboard <span className="sr-only">(current)</span></span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => setCurrentPage(Pages.Submissions)} data-toggle={widthState === true ? "collapse" : ""} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Inzendingen</span>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={() => setCurrentPage(Pages.Submissionform)} data-toggle={widthState === true ? "collapse" : ""} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Indienen</span>
            </li>
          </ul>
          <div className="form-inline my-2 my-lg-0 navbar-info">
            <li className="date mr-3">{new Date().toLocaleDateString('nl-NL')} | <Clock format={'HH:mm:ss'} ticking={true} /> | Week {weekNumber}</li>
            <button className="btn btn-secondary my-2 my-sm-0" onClick={logOut}>Uitloggen</button>
          </div>
        </div>
      </nav>
      <div className="container dashboard-wrapper">
      {(() => {
        if (currentPage === Pages.Home) {
          return (
          <div>
            <h1>Dashboard</h1>
            <hr/>
            <div className="row" id="tiles">
              <div className="tile flex-center" onClick={() => setCurrentPage(Pages.Submissions)}><IoMailUnread /> <span>Inzendingen</span></div>
              <div className="tile flex-center" onClick={() => setCurrentPage(Pages.Submissionform)}><HiDocumentAdd /> <span>Indienen</span></div>
            </div>
          </div>
          )
        }else if(currentPage === Pages.Submissions){
          return (
            <div className="mb-3">
              <h1>Inzendingen</h1>
              <hr/>
              <SubmissionList token={token}/>
            </div>
          )
        }else if(currentPage === Pages.Submissionform){
          return (
            <div className="mb-5">
              <div className="flex-center"><h1>Indienen</h1><span className="mla"><LanguageSelector/></span></div>
              
              <hr/>
              <SubmissionForm/>
            </div>
          )
        }
      })()}
      </div>
    </div>
  );
}
