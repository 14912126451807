import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import EmployeePage from "./components/pages/employee.component";
import Dashboard from "./components/dashboard/dashboard.component";
import HomePage from "./components/pages/home.component";
import { LangProvider } from "./contexts/lang-context";
import EmployerPage from "./components/pages/employer.component";
// import Construction from "./components/pages/construction.component";

class App extends Component {
  render() {
    return (
      <div>
        <LangProvider>
          <Switch>
            {/* <Route exact path="/" component={Construction} /> */}

            <Route exact path="/" component={HomePage} />
            <Route exact path="/werknemers" component={EmployeePage} />
            <Route exact path="/werkgevers" component={EmployerPage} />
            <Route exact path="/admin" component={Dashboard} />
          </Switch>
        </LangProvider>
      </div>
    );
  }
}

export default App;
