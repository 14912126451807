import SubmissionFormContact from "../submission/submission-contact.component";
import React from "react";
import FooterComponent from "../elements/footer.component";
import HeaderComponent from "../elements/header.component";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function EmployerPage(props) {

  return (

    <div className="home-wrapper subpage">
      <div className="mt-3">
      <HeaderComponent/>
        <div style={{backgroundColor:"#e0e0e0"}} className="pb-5">
          <div className="container pb-5 pt-5">
          <h2 className="mb-3">Werkgevers</h2>
            <SubmissionFormContact />
          </div>
        </div>
        <FooterComponent/>
      </div>
    </div>
  );
}
