import prohrm from "../../img/prohrm-logo.png";
import nbbu from "../../img/nbbu-logo.jpeg";
import sna from "../../img/sna-logo.png";
import React from "react";
import FooterComponent from "../elements/footer.component";
import HeaderComponent from "../elements/header.component";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function HomePage(props) {

  return (

    <div className="home-wrapper">
      <div className="mt-3">
        <HeaderComponent/>
        <div className="intro-wrapper"></div>
        <div className="container-c pb-5 pt-5 subsection">
          <h2>Bij Global Match werken we elke dag hard om mensen met werk te verbinden waar ze zin in hebben en zin in houden. Dat is werk dat past bij hun capaciteiten, ambities en voorkeuren. Werk waarin ze hun talent kunnen benutten en ontwikkelen, dat voldoende uitdaging en afwisseling biedt. En in een omgeving die voldoende inclusief, divers en sociaal is.</h2>
        </div>
        <div className="homegrid">
          <div className="row">
            <div className="col-md-6 col-text">
              <h3>WERKNEMERS</h3>
              <p>
                Op zoek naar een baan?<br />
                Wellicht kunnen wij je helpen via uitzenden of payroll. Wij zijn actief in meerdere sectoren en kunnen dan ook gericht voor je zoeken en direct voorstellen aan werkgevers in Nederland.
              </p>
              <button onClick={() => {window.location.href="/werknemers"}} className="btn-grid">Inschrijven</button>
            </div>
            <div className="col-md-6 col-img" id="workerimg">
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-img" id="bossimg">
            </div>
            <div className="col-md-6 col-text">
              <h3>WERKGEVERS</h3>
              <p>
                Een goede samenwerkingspartner die meedenkt en maatwerk levert, is niet meer weg te denken uit de personeelsvoorziening. Wij garanderen onze opdrachtgevers dan ook het "zorgeloos personeel inlenen" concept. Kort, lang, tijdelijk of vast, het is voor ons geen enkel probleem.
              </p>
              <button onClick={() => {window.location.href="/werkgevers"}} className="btn-grid">Contactformulier</button>
            </div>
          </div>
        </div>
        <div className="container-c pb-5 pt-5 subsection">
          <div className="mb-3">
            <img src={prohrm} alt="PRO HRM logo" style={{maxHeight: 30}} />
          </div>
          <div>
            <p>
              ONZE BACKOFFICE WERKZAAMHEDEN WORDEN VERZORGD DOOR <b>PRO HRM</b>
              <br />
              Met hun adviseurs en organisatie streven zei voortdurend naar de hoogst haalbare kwaliteit.
              <br />
              Daar horen ook kwaliteitskeurmerken bij:
            </p>
          </div>

          <div className="certificates">
            <div className="row">
              <div className="col col-img"><img src={nbbu} alt="nbbu logo" /></div>
              <div className="col col-img"><img src={sna} alt="sna logo" /></div>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </div>
    </div>
  );
}
