import http from "../http-common";

class SubmissionDataService {
  getAll() {
    return http.get("/read.php");
  }

  create(data) {
    return http.post("/create.php", data);
  }

  update(data) {
    return http.post(`/update.php`, data);
  }

  deleteSelected(data) {
    return http.post(`/delete.php`, data);
  }
}

export default new SubmissionDataService();