import { useState } from 'react';

export default function useMessage() {
  const [message, setMessage] = useState({});
  const [timeoutId, setTimeoutId] = useState(0);

  const setError = (msg) => {
    clearTimeout(timeoutId);
    var res = {};
    res["css"] = "message-error";
    res["msg"] = msg;
    setMessage(res);
    var id = setTimeout(() => {
      setMessage({});
    }, "7000");
    setTimeoutId(id);
  };

  const setMsg = (msg) => {
    clearTimeout(timeoutId);
    var res = {};
    res["css"] = "message-success";
    res["msg"] = msg;
    setMessage(res);
    var id = setTimeout(() => {
      setMessage({});
    }, "7000");
    setTimeoutId(id);
  };

  return {
    setMsg: setMsg,
    setError: setError,
    message
  }
}