import React from "react";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function HeaderComponent({ setSubmitted }) {


  return (
    <header>
      <div className="container-c mb-3">
        <div className="flex-center"><Link to={"/"}><img src={logo} className="home-logo" alt="GlobalMatch logo" /></Link><span className="mlta"><Link to={"/werknemers"}><button className="btn-nav">Werknemers</button></Link><Link to={"/werkgevers"}><button className="btn-nav">Werkgevers</button></Link></span></div>
      </div>
    </header>
  );
}
