import React, { useContext } from "react";
import { LangContext } from "../contexts/lang-context";
import ReactFlagsSelect from "react-flags-select";

const LanguageSelector = (props) => {
  const [lang, setLang] = useContext(LangContext);

  let handleChange = (code) => {
    setLang(code);
    localStorage.setItem("lang", code);
    document.documentElement.setAttribute("lang", code);
  };

  return (
    <>
      <ReactFlagsSelect
      showSelectedLabel={false}
      showOptionLabel={false}
        countries={["NL", "GB", "UA"]}
        fullWidth={false}
        alignOptionsToRight
        selected={lang}
        onSelect={(code) => handleChange(code)}
      />
    </>
  );
};
export default LanguageSelector;